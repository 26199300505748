import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from 'scenes/navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setUserStatus } from 'state/index';
import UserWidget from 'scenes/widgets/UserWidget';

const HomePage = () => {
  const [editMode, setEditMode] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isMakingPayment, setIsMakingPayment] = useState(false);
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user);
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const user_id = userProfile._id;

  const [formData, setFormData] = useState({
    country: userProfile.country || '',
    city: userProfile.city || '',
    state: userProfile.state || '',
    email: userProfile.email || '',
    phone: userProfile.phone || '',
    description: userProfile.description || '',
    imageUrl: userProfile.imageUrl || ''
  });

  useEffect(() => {
    fetchUserData();
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://www.checkaroundme.com/api/v1/checkaroundme/auth/me/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.success) {
        const updatedUser = response.data.user;
        dispatch(setUserStatus({ status: updatedUser.status }));
        setFormData({
          country: updatedUser.country || '',
          city: updatedUser.city || '',
          state: updatedUser.state || '',
          email: updatedUser.email || '',
          phone: updatedUser.phone || '',
          description: updatedUser.description || '',
          imageUrl: updatedUser.imageUrl || '' // Update imageUrl in formData
        });
      } else {
        console.error('Failed to fetch user data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      alert('Error fetching user data: ' + error.message);
    }
  };

  const handleInputChange = (event) => {
    const { id, value, files } = event.target;
    if (id === "profilePicture") {
      setFormData({
        ...formData,
        imageUrl: files[0] // Set imageUrl to the selected file
      });
    } else {
      setFormData({
        ...formData,
        [id]: value
      });
    }
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          imageUrl: file
        }));
      };
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    try {
      let formData = new FormData();
      formData.append('email', formData.email);
      formData.append('phone', formData.phone);
      formData.append('description', formData.description);
      formData.append('state', formData.state);
      formData.append('city', formData.city);
      formData.append('country', formData.country);
      formData.append('profilePicture', formData.profilePicture); // Add the profile picture file to FormData

      
      const response = await axios.post(`https://www.checkaroundme.com/api/v1/auth/updatedetails/${user_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data);
      // Handle response and update UI accordingly
    } catch (error) {
      console.error('Error updating details:', error);
      // Handle error and display appropriate message to the user
    } finally {
      setIsSaving(false);
    }
  };

  console.log(formData)
  

  const columnStyle = {
    backgroundColor: '#f8f9fa',
    fontFamily: 'Rubik',
    fontSize: '12px',
  };

  const formStyle = {
    backgroundColor: 'white',
    fontFamily: 'Rubik',
    fontSize: '12px',
  };

  const getStatusColor = (status) => {
    return status === 'Pending' ? 'bg-danger text-white' : (status === 'Verified' ? 'bg-success text-white' : '');
  };

  const getButtonText = (status) => {
    return status === 'Pending' ? 'Pending Verification' : (status === 'Verified' ? 'Verified' : '');
  };

  const handlePayment = async () => {
    console.log(userProfile);
    setIsMakingPayment(true);
    try {
      const response = await axios.post(`https://www.checkaroundme.com/api/v1/checkaroundme/payments/make-payment/${userProfile._id}`);
      if (response.data.success) {
        window.location.href = response.data.payment.payment_url;
      } else {
        console.error('Payment initiation failed');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('Error initiating payment: ' + error.message);
    } finally {
      setIsMakingPayment(false);
    }
  };

  return (
    <Box>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3" style={columnStyle}>
            <div className="position-relative">
              <img className="rounded-circle" width="100px" height="100px" alt="Profile" src={userProfile.imageUrl} style={{ marginTop: '10px', marginBottom: '10px'}} />
            </div>
            <hr className="my-1" />
            <div className="mt-3">
              <button className={`btn ${getStatusColor(userProfile.status)}`}>{getButtonText(userProfile.status)}</button>
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <i style={{ color: 'grey'}} className="fas fa-user mr-2 p-2"></i>
              {userProfile.username}
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <i style={{ color: 'grey'}} className="fas fa-map-marker-alt mr-2 p-2"></i>
              {userProfile.country}
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <i style={{ color: 'grey'}} className="fas fa-city mr-2 p-2"></i>
              <strong style={{ color: 'grey'}}>City:</strong> {userProfile.city}
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <i style={{ color: 'grey'}} className="fas fa-flag-usa mr-2 p-2"></i>
              <strong style={{ color: 'grey'}}>State:</strong> {userProfile.state}
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <i style={{ color: 'grey'}} className="fas fa-envelope mr-2 p-2"></i>
              <strong style={{ color: 'grey'}}>Email:</strong> {userProfile.email}
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <i style={{ color: 'grey'}} className="fas fa-flag-usa mr-2 p-2"></i>
              <strong style={{ color: 'grey'}}>Phone Number:</strong> {userProfile.phone}
            </div>
            <hr className="my-1" />
            <div style={{ marginTop: '10px', marginBottom: '10px'}}>
              <strong>Description:</strong> {userProfile.description}
            </div>
            {userProfile.status === 'Verified' ? (
              <div style={{ marginTop: '10px', marginBottom: '10px'}}>
                <i style={{ color: 'grey'}} className="fas fa-info-circle mr-2 p-2"></i>
                <strong>Your Profile is Active</strong>
              </div>
            ) : (
              <div style={{ marginTop: '10px', marginBottom: '10px'}}>
                <i style={{ color: 'grey'}} className="fas fa-info-circle mr-2 p-2"></i>
                <strong>Your Profile is Inactive Until After Payment <br /> Pay Now to get 3 Months Free Subscription</strong>
              </div>
            )}
            <hr className="my-1" />
            <div className="col-md-4">
              {userProfile.status !== 'Verified' && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handlePayment}
                  disabled={isMakingPayment}
                >
                  {isMakingPayment ? 'Processing...' : 'Make Payment'}
                </button>
              )}
            </div>
          </div>
          <div className="col-md-8" style={formStyle}>
            
        {editMode && (
          <form onSubmit={handleSubmit}>
            <div className="form-group" style={{ marginTop: '20px' }} >
                  <label htmlFor="profilePicture">Profile Picture:</label>
                  <input type="file" className="form-control-file" id="profilePicture" onChange={handleProfilePictureChange} />
                </div>
            <br />
            <div className="form-group">
              <label htmlFor="country">Country:</label>
              <input type="text" className="form-control" id="country" onChange={handleInputChange} value={formData.country} />
            </div>
            <br />
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input type="text" className="form-control" id="city" onChange={handleInputChange} value={formData.city} />
            </div>
            <br />
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <input type="text" className="form-control" id="state" onChange={handleInputChange} value={formData.state} />
            </div>
            <br />
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="text" className="form-control" id="email" onChange={handleInputChange} value={formData.email} />
            </div>
            <br />
            <div className="form-group">
              <label htmlFor="phone">Phone Number:</label>
              <input type="text" className="form-control" id="phone" onChange={handleInputChange} value={formData.phone} />
            </div>
            <br />
            <div className="form-group">
              <label htmlFor="description">Description:</label>
              <textarea className="form-control" id="description" onChange={handleInputChange} value={formData.description} rows="5" maxLength="1000"></textarea>
            </div>
            <br />
            <button type="submit" className="btn btn-primary" style={{marginBottom: '20px'}} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </form>
        )}
        </div>
        </div>
        </div>
        </Box>
        );
};

export default HomePage;