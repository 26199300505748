import React, { useState, useEffect } from 'react';
import { Card, CardContent, FormControl, InputLabel, Input } from "@mui/material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const verifySchema = yup.object().shape({
  verificationCode: yup.string().required("Verification code is required"),
});

const initialValuesVerification = {
  verificationCode: "",
};

const VerifyPage = () => {
  const { palette } = useTheme();
  const [verificationCode, setVerificationCode] = useState('');
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [resendMessage, setResendMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isTimerRunning && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setIsTimerRunning(false);
    }
  }, [isTimerRunning, timer]);

  const startResendTimer = () => {
    setTimer(300); // 5 minutes in seconds
    setIsTimerRunning(true);
    // Call API to resend OTP here
    resendOTP()
      .then(response => {
        console.log("Resent OTP successfully:", response.data);
        setResendMessage(response.data.message);
        setSuccessMessage('New OTP sent to your email');
        setErrorMessage('');
      })
      .catch(error => {
        console.error("Error resending OTP:", error);
        setErrorMessage('Failed to resend OTP');
        setSuccessMessage('');
      });
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    // Handle verification logic here
    console.log(values);
    verifyOTP(values.verificationCode)
      .then(response => {
        console.log("Verification successful:", response.message);
        setSuccessMessage('Verification successful');
        setErrorMessage('');
        // Handle success, navigate to appropriate page

        navigate('/')
      })
      .catch(error => {
        console.error("Error verifying OTP:", error);
        setErrorMessage('Invalid verification code');
        setSuccessMessage('');
        // Handle error
      });
  };

  const verifyOTP = async (otp) => {
    const apiUrl = 'https://www.checkaroundme.com/api/v1/checkaroundme/auth/verify';
    const registeredEmail = localStorage.getItem('registeredEmail'); // Retrieve email from storage
    const payload = { email: registeredEmail, verificationCode: otp }; // Adjust payload structure as per API documentation
    console.log(payload.email)
    try {
      const response = await axios.post(apiUrl, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const resendOTP = async () => {
    const apiUrl = 'https://www.checkaroundme.com/api/v1/checkaroundme/auth/resendOtp';
    const registeredEmail = localStorage.getItem('registeredEmail'); // Retrieve email from storage
    const payload = { email: registeredEmail };
    console.log(apiUrl, payload)
    try {
      const response = await axios.post(apiUrl, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      backgroundColor={palette.background.default}
    >
      <Card elevation={3} style={{ margin: '20px', padding: '10px', maxWidth: '800px', width: '90vw' }}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <a href="https://www.checkaroundme.com" target="_blank" style={{ textDecoration: 'none' }} rel="noopener noreferrer"> 
              <img src='https://check-around-me-asset.s3.eu-west-3.amazonaws.com/6658-01.png' alt='Logo' width='150px' style={{ marginBottom: '20px' }} />
            </a>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>
              Verify Your Account
            </Typography>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValuesVerification}
              validationSchema={verifySchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: "span 4" },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel htmlFor="verificationCode">Verification Code</InputLabel>
                      <Input
                        id="verificationCode"
                        type="text"
                        value={values.verificationCode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="verificationCode"
                        error={Boolean(touched.verificationCode && errors.verificationCode)}
                        aria-describedby="verificationCode-helper-text"
                      />
                      {touched.verificationCode && errors.verificationCode && (
                        <Typography color="error" id="verificationCode-helper-text">
                          {errors.verificationCode}
                        </Typography>
                      )}
                    </FormControl>
                    <Button
                      variant="contained"
                      disabled={isTimerRunning && timer > 0}
                      onClick={startResendTimer}
                      sx={{ gridColumn: "span 4", justifySelf: "center", marginTop: '10px' }}
                    >
                      {isTimerRunning && timer > 0 ? `Resend OTP in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}` : 'Resend OTP'}
                    </Button>
                    {resendMessage && (
                      <Typography variant="body1" color="success" sx={{ gridColumn: "span 4", textAlign: "center", marginTop: '10px' }}>
                        {resendMessage}
                      </Typography>
                    )}
                    {successMessage && (
                      <Typography variant="body1" color="success" sx={{ gridColumn: "span 4", textAlign: "center", marginTop: '10px' }}>
                        {successMessage}
                      </Typography>
                    )}
                    {errorMessage && (
                      <Typography variant="body1" color="error" sx={{ gridColumn: "span 4", textAlign: "center", marginTop: '10px' }}>
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      type="submit"
                      sx={{
                        p: "1rem",
                        backgroundColor: palette.primary.main,
                        color: palette.background.alt,
                        "&:hover": { color: palette.primary.main },
                      }}
                    >
                      VERIFY
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VerifyPage;