import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePaymentInputs } from "react-payment-inputs";
//import "./CardDetailsPage.css";


const PaymentPage = () => {
  const form = useRef();
  const navigate = useNavigate();
  const {
    meta,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();


  const [checked, setChecked] = useState(true);
  const [cardNumber, setCardNumber] = useState("");
  const [details, setDetails] = useState({
    expiryDate: "",
    cvc: "",
    NomDuClient: ""
  });

  const handleChange = (e) => {
    setDetails((prevFormDetails) => ({
      ...prevFormDetails,
      [e.target.name]: e.target.value
    }));
  };

  const handleChangeCardNumber = (e) => {
    setCardNumber(
      e.target.value
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim()
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (meta.isTouched && meta.error) ||
      Number(cardNumber.length) < 19 ||
      cardNumber.trim().length === 0 ||
      details.expiryDate.trim().length === 0 ||
      details.cvc.trim().length === 0 ||
      details.NomDuClient.trim().length === 0 ||
      checked === true
    ) {
      setChecked(true);
      console.log("not submit");
    } else {
      setChecked(false);
      navigate("");
    }
  };

  const handleCheck = () => {
    console.log("ok");
    setChecked(false);
  };

  return (
    <form ref={form} className="form" onSubmit={handleSubmit}>
      <header>
        <div className="TitleSecure">
          <h3>Payment Details</h3>
        </div>
        <div className="Amont">
          <p>Amount:</p>
          <label className="price">₦1100</label>
        </div>
      </header>
      <main>
        {meta.isTouched && meta.error ? (
          <span className="span">Error: {meta.error}</span>
        ) : (
          <span className="span"></span>
        )}
        <div className="NomDuClient">
          <label>Name on Card</label>
          <input name="NomDuClient" onChange={handleChange} />
        </div>
        <div className="NumDeCarte">
          <label>Number on Card</label>
          <input
            onChange={handleChangeCardNumber}
            placeholder="Valid Card Number"
            name="cardNumber"
            maxLength="19"
            value={cardNumber}
          />
        </div>
        <div className="DateEtCvc">
          <div className="Date">
            <label>Expiration Date</label>
            <input
              {...getExpiryDateProps({ onChange: handleChange })}
              placeholder="MM/YR"
              name="expiryDate"
            />
          </div>
          <div className="CvC">
            <label>CvC</label>
            <input
              {...getCVCProps({ onChange: handleChange })}
              name="cvc"
              maxLength="3"
            />
          </div>
        </div>
        <div className="terme">
          <input type="checkbox" onChange={handleCheck} />
          <p className="TermeConfidentialite">
            Terms and Condition <Link to="#">Confidential</Link>
          </p>
        </div>
        <input
          disabled={checked}
          type="submit"
          value="Pay Now"
          className="btn"
        />
      </main>
      <footer>
        <img className="img1" src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/methode.jpg" alt="" />
      </footer>
    </form>
  );
}

export default PaymentPage;