import React, { useState } from 'react';
import { Card, CardContent, FormControl, InputLabel, Input, Select, MenuItem, Button, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff, Home } from "@mui/icons-material";
import countries from "../../utils/countries.json";
import { setLogin } from "state";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar from 'scenes/navbar';

const LoginPage = () => {
  const [edescription, setDescription] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('Nigeria');
  const [descriptionError, setDescriptionError] = useState(false);
  const [epassword, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [termsError, setTermsError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    role: '',
    password: epassword,
    service: '',
    state: '',
    city: '',
    country: 'Nigeria',
    description: edescription
  });

  const [errors, setErrors] = useState({
    username: '',
    phone: '',
    role: '',
    password: '',
    service: '',
    state: '',
    city: '',
    country: '',
    description: ''
  });
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...values, [name]: value });
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setFormData({ ...values, country: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    let value = e.target.value;
    // Trim excess characters
    if (value.length > 350) {
      value = value.substring(0, 350);
      setDescriptionError(true); // Set error state if character limit exceeded
    } else {
      setDescriptionError(false);
    }
    setFormData({ ...values, description: value });
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setTermsError(false); // Reset terms error state
    setEmailError('');
    setIsLoading(true);

    // Email validation for both login and register forms
    if (!validateEmail(values.email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    // Check for empty inputs
    const newErrors = {};
    
    // If there are any errors, stop form submission
    if (!isLogin) {
        if (Object.keys(newErrors).length > 0) {
        return;
      }
      for (const key in values) {
        if (!values[key]) {
          newErrors[key] = 'This field is required.';
        }
      }
      setErrors(newErrors);
      //console.log(Object.values(newErrors))
      // Process signup form submission
      const termsCheckbox = document.getElementById('invalidCheck');
      if (termsCheckbox.checked) {
        // Terms and conditions agreed
        //console.log('Signing up...');
        //console.log(values);

        // Clear email error if email is valid
        setEmailError('');
        setLoadError('');
        const res = await register(values);
        //console.log(res);
      } else {
        // Terms and conditions not agreed
        setTermsError(true); // Set terms error state
        //console.log(termsCheckbox.checked);
        setIsLoading(false);
      }
    } else {
      // Process login form submission
      //console.log('Logging in...');
      const res = await login();
    
      console.log(res);
    }
    
  };


  const generatePassword = () => {
    // Generate a random password (for demonstration purposes)
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!^*()<>?-=;.,';
    let randomPassword = '';
    for (let i = 0; i < 12; i++) { // Adjust the length of the password as needed
      randomPassword += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData({ ...values, password: randomPassword });
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const register = async (values) => {
    setIsLoading(true);
    setLoadError('');
  
    try {
      const savedUserResponse = await fetch(
        "https://www.checkaroundme.com/api/v1/checkaroundme/auth/register", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: values.username,
            email: values.email.toLowerCase(),
            phone: values.phone,
            role: values.role,
            description: values.description,
            password: values.password,
            service: values.service,
            city: values.city,
            state: values.state,
            country: values.country,
          })
        }
      );
      const savedUser = await savedUserResponse.json();
      console.log(savedUser);
  
      if (savedUser.success === true) {
        // Store email in local storage, state, or context for later use
        localStorage.setItem('registeredEmail', values.email); // Example: using localStorage
  
        navigate("/verify");
      } else {
        const errorMessage = savedUser.message || 'An error occurred during registration.';
        setLoadError(errorMessage);
        throw new Error(errorMessage);
      }
    } catch (error) {
      setLoadError(error.message || 'An error occurred during registration.');
    } finally {
      setIsLoading(false);
    }
  };  

  const login = async () => {
    const loggedInResponse = await fetch("https://www.checkaroundme.com/api/v1/checkaroundme/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: values.email.toLowerCase(), password: values.password}),
    });

    try {
      const loggedIn = await loggedInResponse.json();
      console.log(loggedIn)
      if (loggedIn.success === false) {
          const errorMessage = await loggedIn.Error;
          console.log(errorMessage)
          setLoadError(errorMessage || 'An error occurred during login.');
          throw new Error(errorMessage);
      }
      if (loggedIn.user.success) {
        dispatch(
          setLogin({
            user: loggedIn.user,
            token: loggedIn.token,
          })
        );
        navigate("/home");
      } else {
        const errorMessage = await loggedIn.Error;
        throw new Error(errorMessage);
      }
      } catch (error) {
        setLoadError(error.message || 'An error occurred during login.');

      } finally {
        setIsLoading(false);
      }
  };

  return (
    <div>
      <div style={{ textAlign: 'start', padding: '5px', background: '#0F52BA', backgroundColor: "rgb(58, 58, 137)"}}>
        <a href="https://www.checkaroundme.com" target="_blank" style={{ textDecoration: 'none' }} rel="noopener noreferrer"> 
          <Home fontSize="large" style={{ verticalAlign: 'middle', color: 'white' }} />
          <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/6658-01.png" alt="Home" width={100} />
        </a>
      </div>
      <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
      <div>
        <Card elevation={3} style={{ margin: '20px', padding: '10px', maxWidth: '800px', width: '90vw', '@media (min-width: 1000px)': { maxWidth: '800px', width: '90vw' } }}>
          <CardContent>
            
            <form className="needs-validation" noValidate onSubmit={handleFormSubmit}>
              <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '24px', fontFamily: 'Poppins' }}>
                {/* <img src='https://check-around-me-asset.s3.eu-west-3.amazonaws.com/logo.png' alt='Logo' width='150px' /> */}
                <h3>{isLogin ? 'Welcome to Checkaroundme' : 'Sign Up to Checkaroundme'}</h3>
              </div>
              {isLogin && (
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '24px', fontFamily: 'Poppins' }} className="col-md-8">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="validationCustom02">Email</InputLabel>
                      <Input id="validationCustom02" onChange={handleChange} name='email' value={values.email} placeholder=" " required />
                      {emailError && (
                        <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                          {emailError}
                        </small>
                      )}
                    </FormControl>
                  </div>
                  
                  <div className="col-md-8">
                    <FormControl fullWidth>
                      <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins', }} htmlFor="validationCustomPassword">Password</InputLabel>
                      <Input
                        id="validationCustomPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder=" "
                        name='password'
                        value={values.password}
                        required
                        onChange={handleChange} // Add onChange handler
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label={showPassword ? "Hide password" : "Show password"}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>

                </div>
              )}
              {!isLogin && (
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustomUsername">Username</InputLabel>
                      <Input id="validationCustomUsername" value={values.username} onChange={handleChange} name='username' placeholder=" " required />
                      {errors.username && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.username}
                      </small>}
                    </FormControl>
                  </div>
                  <div className="col-md-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustom02">Email</InputLabel>
                      <Input id="validationCustom02" placeholder=" " onChange={handleChange} name='email' value={values.email} required />
                      {emailError && (
                        <div className="row">
                          <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                            {emailError}
                          </small>
                        </div>
                      )}
                    </FormControl>
                  </div>
                </div>
              )}
              
              <div className="row">
              {!isLogin && (
                <div className="col-md-6 mb-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="validationCustom03">Phone</InputLabel>
                    <Input id="validationCustom03" onChange={handleChange} name='phone' value={values.phone} style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} placeholder=" " required />
                    {errors.phone && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.phone}
                      </small>}
                  </FormControl>
                </div>
                )}
                {!isLogin && (
                  <div className="col-md-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustom06">Service</InputLabel>
                      <Input id="validationCustom06" onChange={handleChange} name='service' value={values.service} placeholder=" " required />
                      {errors.service && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.service}
                      </small>}
                    </FormControl>
                  </div>
                )}
              </div>
              {!isLogin && (
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustomPassword">Password</InputLabel>
                    <FormControl fullWidth>
                      <Input
                        id="validationCustomPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder=" "
                        value={values.password}
                        onChange={handleChange} name='password'
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label={showPassword ? "Hide password" : "Show password"}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.password}
                      </small>}
                    </FormControl>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Button variant="contained" style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins', backgroundColor: 'white' }} onClick={generatePassword}>Auto-generate Password</Button>
                  </div>
                </div>
              )}
              {!isLogin && (
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins', marginLeft: '15px' }} htmlFor="validationCustom04">Account Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="accountTypeLabel"
                        id="validationCustom04"
                        value={values.role}
                        onChange={handleChange} name='role'
                        required 
                      >
                        <MenuItem value="merchant">Merchant</MenuItem>
                      </Select>
                      {errors.role && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.role}
                      </small>}
                    </FormControl>
                  </div>
                  <div className="col-md-6 mb-3">
                    <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins', marginLeft: '15px' }} htmlFor="validationCustom04">Country</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="countryLabel"
                        id="validationCustom07"
                        value={values.country}
                        onChange={handleCountryChange}
                        required
                      >
                        {Object.entries(countries).map(([code, name]) => (
                          <MenuItem key={code} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {!isLogin && (
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustom08">State</InputLabel>
                      <Input id="validationCustom08" onChange={handleChange} name='state' value={values.state} placeholder=" " required />
                      {errors.state && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.state}
                      </small>}
                    </FormControl>
                  </div>
                  <div className="col-md-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustom09">City</InputLabel>
                      <Input id="validationCustom09" onChange={handleChange} name='city' value={values.city} placeholder=" " required />
                      {errors.city && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                        {errors.city}
                      </small>}
                    </FormControl>
                  </div>
                </div>
              )}
              {!isLogin && (
              <div className={`mb-3 ${descriptionError ? 'has-error' : ''}`}>
                <FormControl fullWidth>
                  <InputLabel style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} htmlFor="validationCustom10">Description</InputLabel>
                  <Input
                    id="validationCustom10"
                    placeholder=" "
                    value={values.description}
                    onChange={handleDescriptionChange}
                    required
                    multiline
                    rows={4}
                  />
                  {errors.description && <small style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                    {errors.description}
                  </small>}
                </FormControl>
                <small style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} className="text-danger">{descriptionError && 'Description exceeds 350 characters'}</small><br />
                <small style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins' }} className="form-text">{values.description.length} / 350 characters</small>
              </div>
              )}
              {!isLogin && (
              <div style={{ fontSize: '14px', fontFamily: 'Poppins' }} className="form-check mb-3">
                <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                <label className="form-check-label" htmlFor="invalidCheck">
                  Agree to terms and conditions
                </label>
              </div>
              )}
              {!isLogin && termsError && (
                <small style={{ fontSize: '12px', fontFamily: 'Poppins' }} className="text-danger">
                  You must agree before submitting.
                </small>
              )}
              <div className="d-flex justify-content-end align-items-center">
                {isLogin ? (
                  <button
                    style={{
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      marginTop: '10px',
                      marginRight: window.innerWidth <= 768 ? '10px' : '120px', // Default margin right: 0px, 10px for small screens
                      padding: '5px 10px', // Adjust padding to change button size
                    }}
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleFormSubmit} // Assuming handleFormSubmit is the function to handle form submission
                    disabled={isLoading} // Disable button when loading
                  >
                    {isLoading ? 'Loading...' : 'Login'}
                  </button>
                ) : (
                  <button
                    style={{
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      marginTop: '10px',
                      marginRight: '10px', // Default margin right
                      padding: '5px 10px', // Adjust padding to change button size
                    }}
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleFormSubmit} // Assuming handleFormSubmit is the function to handle form submission
                    disabled={isLoading} // Disable button when loading
                  >
                    {isLoading ? 'Loading...' : 'Sign Up'}
                  </button>
                )}
              </div>

              {loadError && <div style={{ color: 'red', marginTop: '10px' }}>{loadError}</div>} {/* Display error message if exists */}

              <p style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins' }}>{isLogin ? "Don't have an account? " : "Already have an account? "}<a href="#" onClick={toggleForm}>{isLogin ? "Sign Up here." : "Login here."}</a></p>
            </form>
            {/* )}
            </Formik> */}
          </CardContent>
        </Card>
      </div>
    </div>
    </div>
  );
}

export default LoginPage;
