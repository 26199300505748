import React from 'react'

const ProfilePage = () => {
  return (
    <div>
      ProfilePage
    </div>
  )
}

export default ProfilePage;
