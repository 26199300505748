import React from 'react';
import { Link } from 'react-router-dom';

const MyComponent = () => {
  return (
    <div>
      {/* Link to a non-existent page */}
      <Link to="/non-existent-page">Non-Existent Page</Link>
    </div>
  );
};

export default MyComponent;
